export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/app": [4,[2]],
		"/app/accounts": [5,[2]],
		"/app/accounts/[accountId]": [6,[2]],
		"/app/accounts/[accountId]/transactions": [7,[2]],
		"/app/create-account-transfer": [9,[2]],
		"/app/create-account": [8,[2]],
		"/app/create-category": [10,[2]],
		"/app/create-envelope-transaction": [12,[2]],
		"/app/create-envelope": [11,[2]],
		"/app/create-transaction": [13,[2]],
		"/app/envelopes": [14,[2]],
		"/app/envelopes/[envelopeId]": [15,[2]],
		"/app/envelopes/[envelopeId]/transactions": [16,[2]],
		"/app/intro": [17,[2]],
		"/app/notifications": [18,[2]],
		"/app/settings": [19,[2]],
		"/app/settings/categories": [20,[2]],
		"/app/settings/categories/[categoryId]": [21,[2]],
		"/app/settings/data": [22,[2]],
		"/app/settings/data/import": [23,[2]],
		"/app/settings/plan": [24,[2]],
		"/app/settings/profile": [25,[2]],
		"/app/settings/profile/edit": [26,[2]],
		"/app/update-account-transfer/[accountTransferId]": [28,[2]],
		"/app/update-account/[accountId]": [27,[2]],
		"/app/update-category/[categoryId]": [29,[2]],
		"/app/update-envelope-transaction/[envelopeTransactionId]": [31,[2]],
		"/app/update-envelope/[envelopeId]": [30,[2]],
		"/app/update-transaction/[transactionId]": [32,[2]],
		"/blog": [33]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';